@keyframes fade-in-and-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(5);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes grow-in {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes grow-out {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(5);
  }
}

@keyframes rapid-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(5);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
