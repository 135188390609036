@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import "../css/colors.css";

/** App styles */
html,
body {
  height: 100%;
  background: #fff;
  overflow: hidden;
  position: relative;
  margin: 0;
}

#root {
  height: 100%;
  display: grid;
}

.App {
  display: grid;
}

h1 {
  font-family: "Bebas Neue", sans-serif;
  text-align: center;
  font-size: 300px;
  font-weight: 400;
  margin: 0;
  align-self: end;
}

main {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: repeat(3, min-content);
}

.red-alert {
  animation: 2500ms infinite flashing-red;
}

@keyframes flashing-red {
  0% {
    background: inherit;
  }

  50% {
    background: #f00;
  }

  100% {
    background: inherit;
  }
}

/* Stage Color Schemes */
.App.stage1 {
  background: var(--violet-blue-crayola);
}

.App.stage2 {
  background: var(--inchworm);
}

.App.stage3 {
  background: var(--yellow-pantone);
}

.App.stage4 {
  background: var(--sky-blue-crayola);
}

.App.stage5 {
  background: var(--orange-pantone);
}
