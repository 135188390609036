@import "../css/animations.css";

/** Hero styles */
.hero path {
  stroke: var(--shadow);
  stroke-width: 5px;
  transform: scale(0.7);
  transform-origin: center;
}

.hero {
  width: var(--square-size);
  height: var(--square-size);
  grid-column: var(--hero-x);
  grid-row: var(--hero-y);
}

.hero svg {
  width: var(--square-size);
  height: var(--square-size);
}

.hero--up svg {
  transform: rotate(0deg);
}

.hero--right svg {
  transform: rotate(90deg);
}

.hero--down svg {
  transform: rotate(180deg);
}

.hero--left svg {
  transform: rotate(270deg);
}

.hero--0 svg path {
  fill: var(--sky-blue-crayola);
}

.hero--1 svg path {
  fill: var(--inchworm);
}

.hero--2 svg path {
  fill: var(--orange-pantone);
}

.hero--3 svg path {
  fill: var(--violet-blue-crayola);
}
