@import "../css/colors.css";

/** Monster styles */
.monster path,
.monster rect {
  stroke: black;
  stroke-width: 1px;
}

.monster svg {
  height: 100%;
  width: 100%;
}

.monster .shape {
  display: none;
}

.monster.color-0 .square {
  display: block;
}

.monster.color-1 .triangle {
  display: block;
}

.monster.color-2 .half-circle {
  display: block;
}

.monster.color-3 .plus {
  display: block;
}

.monster.color-0 .square rect {
  fill: var(--sky-blue-crayola);
}

.monster.color-1 path {
  fill: var(--inchworm);
}

.monster.color-2 path {
  fill: var(--orange-pantone);
}

.monster.color-3 path {
  fill: var(--violet-blue-crayola);
}
