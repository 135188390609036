:root {
  --violet-blue-crayola: #8379d4ff;
  --inchworm: #9ae15fff;
  --yellow-pantone: #f9da00ff;
  --white: #ffffffff;
  --black: #000000ff;
  --shadow: #00000099;
  --light-shadow: #00000066;
  --sky-blue-crayola: #3bdbf6ff;
  --orange-pantone: #ff5400ff;
}
