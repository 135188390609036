/** Field styles */
.field {
  background: #f8f2f4;
  display: grid;
  width: calc(4 * var(--square-size));
  height: calc(4 * var(--square-size));
  grid-gap: 0;
}

.up-field,
.down-field {
  grid-template-columns: repeat(4, var(--square-size));
  height: calc(var(--short-field-size) * var(--square-size));
}

.left-field,
.right-field {
  grid-template-rows: repeat(4, var(--square-size));
  width: calc(var(--long-field-size) * var(--square-size));
}

.up-field {
  grid-column: 2;
  grid-row: 1;
}
.left-field {
  grid-column: 1;
  grid-row: 2;
}
.right-field {
  grid-column: 3;
  grid-row: 2;
}
.down-field {
  grid-column: 2;
  grid-row: 3;
}
