.board {
  margin: auto;
  min-width: calc(
    (2 * var(--long-field-size) * var(--square-size)) + (4 * var(--square-size))
  );

  --short-field-size: 5;
  --long-field-size: 8;
  --square-size: 18px;
}

footer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  justify-items: start;
}

.self-promo {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 16px;
  font-family: "Bebas Neue", sans-serif;
  color: var(--light-shadow);
  display: block;
  text-align: center;
  justify-self: center;
  line-height: 43px;
}

.self-promo a {
  color: var(--light-shadow);
}

@media (min-width: 320px) {
  .board {
    --square-size: 18px;
  }
}

@media (min-width: 600px) {
  .board {
    --square-size: 30px;
  }
}

@media (min-width: 960px) {
  .board {
    --square-size: 40px;
  }
}
