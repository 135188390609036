.control-panel {
  display: flex;
}

button {
  border: 0;
  background: transparent;
  font-size: 28px;
  cursor: pointer;
}

button.muted {
  filter: grayscale(100%);
}
