@import "./colors.css";

:root {
  --h1-size: 100px;
  --h3-size: 24px;
  --h3-shadow: 2px 2px 0 var(--shadow);
}

@media (min-width: 320px) {
  :root {
    --h1-size: 200px;
    --h3-size: 36px;
    --h3-shadow: 2px 2px 0 var(--shadow);
  }
}

@media (min-width: 600px) {
  :root {
    --h1-size: 300px;
    --h3-size: 36px;
    --h3-shadow: 2px 2px 0 var(--shadow);
  }
}

@media (min-width: 960px) {
  :root {
    --h1-size: 300px;
    --h3-size: 48px;
    --h3-shadow: 3px 3px 0 var(--shadow);
  }
}
