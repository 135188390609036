@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@keyframes fade-in-and-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(5);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes grow-in {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes grow-out {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(5);
  }
}

@keyframes rapid-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(5);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

:root {
  --violet-blue-crayola: #8379d4ff;
  --inchworm: #9ae15fff;
  --yellow-pantone: #f9da00ff;
  --white: #ffffffff;
  --black: #000000ff;
  --shadow: #00000099;
  --light-shadow: #00000066;
  --sky-blue-crayola: #3bdbf6ff;
  --orange-pantone: #ff5400ff;
}

:root {
  --h1-size: 100px;
  --h3-size: 24px;
  --h3-shadow: 2px 2px 0 var(--shadow);
}

@media (min-width: 320px) {
  :root {
    --h1-size: 200px;
    --h3-size: 36px;
    --h3-shadow: 2px 2px 0 var(--shadow);
  }
}

@media (min-width: 600px) {
  :root {
    --h1-size: 300px;
    --h3-size: 36px;
    --h3-shadow: 2px 2px 0 var(--shadow);
  }
}

@media (min-width: 960px) {
  :root {
    --h1-size: 300px;
    --h3-size: 48px;
    --h3-shadow: 3px 3px 0 var(--shadow);
  }
}

:root {
  --violet-blue-crayola: #8379d4ff;
  --inchworm: #9ae15fff;
  --yellow-pantone: #f9da00ff;
  --white: #ffffffff;
  --black: #000000ff;
  --shadow: #00000099;
  --light-shadow: #00000066;
  --sky-blue-crayola: #3bdbf6ff;
  --orange-pantone: #ff5400ff;
}

/** Alert */
.alert {
  background: #ffffff;
  display: grid;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 48px;
  font-family: "Bebas Neue", sans-serif;
  z-index: 2;
}

.alert.main-menu h1 {
  font-size: var(--h1-size);
}

.alert .alert-content {
  align-self: center;
  max-width: 640px;
  width: auto;
  padding: 10px;
}

.alert .center {
  text-align: center;
}

.alert h1 {
  margin-bottom: 20px;
  font-size: 48px;
  text-align: center;
  font-weight: 400;
}

.alert h3 {
  font-size: 24px;
  font-weight: 400;
}

.alert .alert-content h1.small-headline {
  text-align: left;
  font-size: 36px;
}

.alert .alert-content p {
  margin: 0 0 15px 0;
  font-family: sans-serif;
  font-size: 18px;
}

.alert.shown {
  animation: fade-in 250ms forwards;
}

.alert.shown .alert-content {
  animation: grow-in 250ms forwards;
}

.alert.animating-out {
  animation: fade-out 250ms forwards;
}

.alert.animating-out .alert-content {
  animation: grow-out 250ms forwards;
}

.alert dl {
  font-size: 24px;
}

.alert dd {
  font: 18px sans-serif;
}

.alert dd + dt {
  margin-top: 15px;
}

.alert a,
.alert a:visited {
  display: inline-block;
  color: var(--black);
  transition: background-color 250ms;
}

.alert a:hover {
  background-color: var(--sky-blue-crayola);
}

/** Menu */
.menu-wrapper {
  /* Coolors palette 1
    --black: #0c0f0a;
    --pink: #ff206e;
    --yellow: #fbff12;
    --blue: #41ead4;
    --white: #fff; */

  --black: #0c0f0a;
  --pink: #f0f;
  --yellow: #ffff00;
  --blue: #0ff;
  --white: #fff;
  font-size: 36px;
  font-family: "Bebas Neue", sans-serif;
  text-align: center;
  color: var(--black);
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.menu {
  padding: 0;
  list-style: none;
  margin: 0;
}

.main-menu .menu {
  font-size: 48px;
}

.menu li {
  transition: 50ms all;
}

.menu li span {
  cursor: pointer;
}

.menu .selected {
  transform: scale(1.3);
  color: var(--blue);
  animation-name: pulse;
  animation-duration: 600ms;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  from {
    text-shadow: 0 0 10px var(--pink);
  }

  50% {
    text-shadow: 0 0 3px var(--pink);
  }

  to {
    text-shadow: 0 0 10px var(--pink);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.board {
  margin: auto;
  min-width: calc(
    (2 * var(--long-field-size) * var(--square-size)) + (4 * var(--square-size))
  );

  --short-field-size: 5;
  --long-field-size: 8;
  --square-size: 18px;
}

footer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  justify-items: start;
}

.self-promo {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 16px;
  font-family: "Bebas Neue", sans-serif;
  color: var(--light-shadow);
  display: block;
  text-align: center;
  justify-self: center;
  line-height: 43px;
}

.self-promo a {
  color: var(--light-shadow);
}

@media (min-width: 320px) {
  .board {
    --square-size: 18px;
  }
}

@media (min-width: 600px) {
  .board {
    --square-size: 30px;
  }
}

@media (min-width: 960px) {
  .board {
    --square-size: 40px;
  }
}

/** Scoreboard styles */
.scoreboard {
  font-size: var(--h3-size);
  font-family: "Bebas Neue", cursive;
  color: var(--white);
  text-shadow: var(--h3-shadow);
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
}

.scoreboard p {
  margin: 0;
}

.high-score {
  text-align: right;
}

/** Monster styles */
.monster path,
.monster rect {
  stroke: black;
  stroke-width: 1px;
}

.monster svg {
  height: 100%;
  width: 100%;
}

.monster .shape {
  display: none;
}

.monster.color-0 .square {
  display: block;
}

.monster.color-1 .triangle {
  display: block;
}

.monster.color-2 .half-circle {
  display: block;
}

.monster.color-3 .plus {
  display: block;
}

.monster.color-0 .square rect {
  fill: var(--sky-blue-crayola);
}

.monster.color-1 path {
  fill: var(--inchworm);
}

.monster.color-2 path {
  fill: var(--orange-pantone);
}

.monster.color-3 path {
  fill: var(--violet-blue-crayola);
}

.ghost {
  height: 40px;
  width: 40px;
  font-size: 24px;
  font-family: "Bebas Neue", cursive;
  animation: 250ms fade-in forwards, 250ms grow-in forwards,
    250ms slide-up 500ms forwards, 250ms fade-out 500ms forwards;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** Queue styles */
.queue {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.queue li {
  height: var(--square-size);
  width: var(--square-size);
  transform: scale(0.6);
}

.up-field .queue {
  flex-direction: column-reverse;
  width: var(--square-size);
}

.left-field .queue {
  flex-direction: row-reverse;
  height: var(--square-size);
}

.right-field .queue {
  flex-direction: row;
  height: var(--square-size);
}

.down-field .queue {
  flex-direction: column;
  width: var(--square-size);
}

/** Field styles */
.field {
  background: #f8f2f4;
  display: grid;
  width: calc(4 * var(--square-size));
  height: calc(4 * var(--square-size));
  grid-gap: 0;
}

.up-field,
.down-field {
  grid-template-columns: repeat(4, var(--square-size));
  height: calc(var(--short-field-size) * var(--square-size));
}

.left-field,
.right-field {
  grid-template-rows: repeat(4, var(--square-size));
  width: calc(var(--long-field-size) * var(--square-size));
}

.up-field {
  grid-column: 2;
  grid-row: 1;
}
.left-field {
  grid-column: 1;
  grid-row: 2;
}
.right-field {
  grid-column: 3;
  grid-row: 2;
}
.down-field {
  grid-column: 2;
  grid-row: 3;
}

/** Homebase styles */
.homebase {
  background: #f0e2e7;
  grid-column: 2;
  grid-row: 2;
  width: calc(var(--square-size) * 4);
  height: calc(var(--square-size) * 4);
  display: grid;
  grid-template-columns: repeat(4, var(--square-size));
  grid-template-rows: repeat(4, var(--square-size));

  --hero-x: 1;
  --hero-y: 1;
}

/** Hero styles */
.hero path {
  stroke: var(--shadow);
  stroke-width: 5px;
  transform: scale(0.7);
  transform-origin: center;
}

.hero {
  width: var(--square-size);
  height: var(--square-size);
  grid-column: var(--hero-x);
  grid-row: var(--hero-y);
}

.hero svg {
  width: var(--square-size);
  height: var(--square-size);
}

.hero--up svg {
  transform: rotate(0deg);
}

.hero--right svg {
  transform: rotate(90deg);
}

.hero--down svg {
  transform: rotate(180deg);
}

.hero--left svg {
  transform: rotate(270deg);
}

.hero--0 svg path {
  fill: var(--sky-blue-crayola);
}

.hero--1 svg path {
  fill: var(--inchworm);
}

.hero--2 svg path {
  fill: var(--orange-pantone);
}

.hero--3 svg path {
  fill: var(--violet-blue-crayola);
}

.control-panel {
  display: flex;
}

button {
  border: 0;
  background: transparent;
  font-size: 28px;
  cursor: pointer;
}

button.muted {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

/** Counter styles */
.counter {
  font-size: var(--h3-size);
  font-family: "Bebas Neue", sans-serif;
  color: var(--white);
  text-shadow: var(--h3-shadow);
  animation-iteration-count: 1;
  animation-duration: 500ms;
  display: block;
  justify-self: right;
}

.counter.shake1 {
  animation-name: shake1;
}

.counter.shake2 {
  animation-name: shake2;
}

.counter.shake3 {
  animation-name: shake3;
}

.counter.shake4 {
  animation-name: shake4;
}

.counter.shake5 {
  animation-name: shake5;
}

@keyframes shake1 {
  0% {
  }
  33% {
    transform: rotate(21deg) scale(3);
  }
  66% {
    transform: rotate(-39deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes shake2 {
  0% {
  }
  33% {
    transform: rotate(-13deg) scale(3);
  }
  66% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes shake3 {
  0% {
  }
  33% {
    transform: rotate(-29deg) scale(3);
  }
  66% {
    transform: rotate(24deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes shake4 {
  0% {
  }
  33% {
    transform: rotate(8deg) scale(3);
  }
  66% {
    transform: rotate(-18deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes shake5 {
  0% {
  }
  33% {
    transform: rotate(2deg) scale(3);
  }
  66% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(0);
  }
}

/** App styles */
html,
body {
  height: 100%;
  background: #fff;
  overflow: hidden;
  position: relative;
  margin: 0;
}

#root {
  height: 100%;
  display: grid;
}

.App {
  display: grid;
}

h1 {
  font-family: "Bebas Neue", sans-serif;
  text-align: center;
  font-size: 300px;
  font-weight: 400;
  margin: 0;
  align-self: end;
}

main {
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: repeat(3, -webkit-min-content);
  grid-template-rows: repeat(3, min-content);
}

.red-alert {
  animation: 2500ms infinite flashing-red;
}

@keyframes flashing-red {
  0% {
    background: inherit;
  }

  50% {
    background: #f00;
  }

  100% {
    background: inherit;
  }
}

/* Stage Color Schemes */
.App.stage1 {
  background: var(--violet-blue-crayola);
}

.App.stage2 {
  background: var(--inchworm);
}

.App.stage3 {
  background: var(--yellow-pantone);
}

.App.stage4 {
  background: var(--sky-blue-crayola);
}

.App.stage5 {
  background: var(--orange-pantone);
}

