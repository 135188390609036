/** Queue styles */
.queue {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.queue li {
  height: var(--square-size);
  width: var(--square-size);
  transform: scale(0.6);
}

.up-field .queue {
  flex-direction: column-reverse;
  width: var(--square-size);
}

.left-field .queue {
  flex-direction: row-reverse;
  height: var(--square-size);
}

.right-field .queue {
  flex-direction: row;
  height: var(--square-size);
}

.down-field .queue {
  flex-direction: column;
  width: var(--square-size);
}
