@import "../css/animations.css";
@import "../css/typography.css";
@import "../css/colors.css";

/** Alert */
.alert {
  background: #ffffff;
  display: grid;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 48px;
  font-family: "Bebas Neue", sans-serif;
  z-index: 2;
}

.alert.main-menu h1 {
  font-size: var(--h1-size);
}

.alert .alert-content {
  align-self: center;
  max-width: 640px;
  width: auto;
  padding: 10px;
}

.alert .center {
  text-align: center;
}

.alert h1 {
  margin-bottom: 20px;
  font-size: 48px;
  text-align: center;
  font-weight: 400;
}

.alert h3 {
  font-size: 24px;
  font-weight: 400;
}

.alert .alert-content h1.small-headline {
  text-align: left;
  font-size: 36px;
}

.alert .alert-content p {
  margin: 0 0 15px 0;
  font-family: sans-serif;
  font-size: 18px;
}

.alert.shown {
  animation: fade-in 250ms forwards;
}

.alert.shown .alert-content {
  animation: grow-in 250ms forwards;
}

.alert.animating-out {
  animation: fade-out 250ms forwards;
}

.alert.animating-out .alert-content {
  animation: grow-out 250ms forwards;
}

.alert dl {
  font-size: 24px;
}

.alert dd {
  font: 18px sans-serif;
}

.alert dd + dt {
  margin-top: 15px;
}

.alert a,
.alert a:visited {
  display: inline-block;
  color: var(--black);
  transition: background-color 250ms;
}

.alert a:hover {
  background-color: var(--sky-blue-crayola);
}
