/** Homebase styles */
.homebase {
  background: #f0e2e7;
  grid-column: 2;
  grid-row: 2;
  width: calc(var(--square-size) * 4);
  height: calc(var(--square-size) * 4);
  display: grid;
  grid-template-columns: repeat(4, var(--square-size));
  grid-template-rows: repeat(4, var(--square-size));

  --hero-x: 1;
  --hero-y: 1;
}
